import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';

import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  * {font-family: arial;
     font-weight: bold;}
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

export default function App() {
  return (
    <Fragment>
      <MainContainer>
        <Header/>
        <Switch>
          <Route exact path='/' component={HomePage}/>
        </Switch>
        <Footer/>
      </MainContainer>
    </Fragment>
  );
}