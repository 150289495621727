import React from 'react';
import styled from 'styled-components';
import TwitterLight from '../assets/twitter_light_blue.png';
import TwitterDark from '../assets/twitter_dark_blue.png';
import InstaLight from '../assets/instagram_pink.png';
import InstaDark from '../assets/instagram_red.png';

const FooterLayout = styled.div`
    width: 100%;
    flex-shrink: 0;
    padding: 4px 0px 4px 0px;
    background-color: #D3D3D3;
    font-size: 0.8em;
    .footer-text {
        list-style-type: none;
        overflow: hidden;
    }
    .footer-elmt {
        float: left;
    }
    .footer-logo {
        float: right;
        margin-right: 20px;
    }
    .aEmail {
        color: #404040;
        text-decoration: none;
    }
    .aEmail:hover {
        color: #000000;
        text-decoration: none;
    }
`;

export default function Footer() {
    return (
        <FooterLayout>
            <ul className='footer-text'>
                <li className='footer-elmt'><a href='mailto:contact@jeanbaptisteoger.com' className='aEmail'>Contact</a></li>
                <li className='footer-logo'>
                </li>
                <li className='footer-logo'>
                </li> 
            </ul>
        </FooterLayout>
    );
}
