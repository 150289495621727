import React from 'react';
import styled from 'styled-components';

const HomePageLayout = styled.div`
    flex-grow: 1;
    
    .liElt {
        list-style: None;
        padding-left: 8px;
        margin-top: 8px;
    }
    .liElt:hover {
        padding-left: 4px;
        border-left: 4px solid #D21104;
        color: #D21104;
    }
    .liLink:visited {
        color: #7D2206;
    }
    .liLink:hover {
        color: #D21104;
    }
    .liLink {
        text-decoration: none;
    }

    .subTitle {
        padding-left: 10px;
        margin-top: 4px;
        font-size: .9em;
        font-style: italic;
    }
`;

export default function HomePage() {
    return (
        <HomePageLayout>
            <h2 className='subTitle'>Data Scientist, London - UK</h2>
            <ul className='ulElt'>
                <li className='liElt'><a href="https://github.com/Jebq" target='_blank' className='liLink'>Github</a></li>
                <li className='liElt'><a href="https://www.kaggle.com/jeabat" target='_blank' className='liLink'>Kaggle</a></li>
            </ul>
        </HomePageLayout>
    );
}
