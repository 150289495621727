import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

const HeaderLayout = styled.div`
    display: flex;
    flex-flow: column;
    align-items: left;

    padding: 8px 8px 0px 8px;
    
    .title {
        color: black;
        font-size: 1.3em;
        text-decoration: none;
        letter-spacing: 0.05em;
    }
`;

export default function Header() {
    return (
        <HeaderLayout>
            <Link to='/' className='title'>Jean-Baptiste Oger</Link>
        </HeaderLayout>
    );
}